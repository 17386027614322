import React, { useEffect } from 'react'
import Icon360 from '../assets/svg/circle360.inline.svg'

const Cursor = () => {
  useEffect(() => {
    const cursor = document.querySelector('#custom-cursor')
    const a = document.querySelectorAll('a')
    const button = document.querySelectorAll('button')
    const cursorPointer = document.querySelectorAll('.cursor-pointer')
    const defaultCursor = `
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -8px;
        background: #FFFFFF;
    `
    const hoverCursor = `
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
        background: #FFFFFF;
    `
    cursor.children[0].style = defaultCursor

    document.addEventListener('mousemove', (e) => {
      let x = e.clientX
      let y = e.clientY

      cursor.style.transform = `translate3d(${x}px, ${y}px, 0)`
    })

    // For all links
    a.forEach((item) => {
      item.addEventListener('mouseover', () => {
        cursor.children[0].style = hoverCursor
      })
      item.addEventListener('mouseleave', () => {
        cursor.children[0].style = defaultCursor
      })
    })

    // For all buttons
    button.forEach((item) => {
      item.addEventListener('mouseover', () => {
        cursor.children[0].style = hoverCursor
      })
      item.addEventListener('mouseleave', () => {
        cursor.children[0].style = defaultCursor
      })
    })

    // For all elements with cursor pointer
    cursorPointer.forEach((item) => {
      item.addEventListener('mouseover', () => {
        cursor.children[0].style = hoverCursor
      })
      item.addEventListener('mouseleave', () => {
        cursor.children[0].style = defaultCursor
      })
    })
  })

  return (
    <div
      id='custom-cursor'
      className='fixed top-0 left-0 z-[100] pointer-events-none mix-blend-difference transition-all duration-[400ms] ease-out hidden'
    >
      <div className='relative rounded-full grid place-items-center overflow-hidden transition-all duration-300 ease-in-out'></div>
      <Icon360 id='360Icon-cursor' className='hidden' />
    </div>
  )
}

export default Cursor
