const MenuItemsClasses = {
  className:
    'md:hover:text-orange md:text-gray-300 md:text-base text-2xl text-black-200 no-underline',
  activeClassName: '!text-black-100',
}

const MenuItemsList = [
  {
    title: 'Products',
    url: '/',
    className: MenuItemsClasses.className,
    activeClassName: MenuItemsClasses.activeClassName,
    submenu: [
      {
        title: 'Imaging',
        description:
          'Sell more vehicles faster with better photos. AI-powered.',
        submenu: [
          {
            title: 'Vumography',
            url: '/vumography',
            status: '',
            description: 'Instant background removal.',
            videoM: 'MKV03',
            videoT: 'TKV03',
            videoD: 'DKV03',
            videoMenu: 'MEKV03',
            selected: true,
          },
          {
            title: 'CS',
            url: '/cs',
            status: '',
            description:
              'Creates interior and exterior images and 360-degree scans at the push of a button. Comes with Vumography.',
            videoM: 'MKV04',
            videoT: 'TKV04',
            videoD: 'DKV04',
            videoMenu: 'MEKV04',
            selected: true,
          },
          {
            title: 'CS Gimbal',
            url: '/cs-gimbal',
            status: '',
            description:
              'Ultra convenient handheld AI assistant for top-notch 360° exterior and interior car images.',
            videoM: 'MKV17',
            videoT: 'TKV17',
            videoD: 'DKV17',
            videoMenu: 'MEKV17',
            selected: true,
          },
        ],
      },
      {
        title: 'Inspection',
        description:
          'Get consistent reports every single time. All thanks to AI-enabled inspection robots.',
        submenu: [
          {
            title: 'UB Scanner',
            url: '/ub-scanner',
            status: '',
            description:
              'Automated drive-thru speed-bump that inspects vehicle underbody.',
            videoM: 'MKV06',
            videoT: 'TKV06',
            videoD: 'DKV06',
            videoMenu: 'MEKV06',
            selected: true,
          },
          {
            title: 'CS Station',
            url: '/cs-station',
            status: '',
            description:
              'Scans the car’s body inch by inch and consistently detects blemishes. Generates reliable condition reports.',
            videoM: 'MKV07',
            videoT: 'TKV07',
            videoD: 'DKV07',
            videoMenu: 'MEKV07',
            selected: true,
          },
          {
            title: 'CS Voyager',
            url: '/cs-voyager',
            status: '',
            description: 'Your robotized Condition Report Writer.',
            videoM: 'MKV10',
            videoT: 'TKV10',
            videoD: 'DKV10',
            videoMenu: 'MEKV10',
            selected: false,
          },
        ],
      },
      {
        title: 'Safety',
        description:
          'Enforce OSHA rules with the power of AI. Reduce safety risks for the benefit of all.',
        submenu: [
          {
            title: 'VM Detector',
            url: '/vm-detector',
            status: '',
            description: 'Save lives at your plant with the power of AI.',
            videoM: 'MKV13',
            videoT: 'TKV13',
            videoD: 'DKV13',
            videoMenu: 'MEKV13',
            selected: false,
          },
        ],
      },
    ],
  },
  {
    title: 'Company',
    url: '/company',
    className: MenuItemsClasses.className,
    activeClassName: MenuItemsClasses.activeClassName,
  },
  {
    title: 'Blog',
    url: '/blog',
    className: MenuItemsClasses.className,
    activeClassName: MenuItemsClasses.activeClassName,
  },
  {
    title: 'Press',
    url: '/press',
    className: MenuItemsClasses.className,
    activeClassName: MenuItemsClasses.activeClassName,
  },
]

export default MenuItemsList
