import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { IoClose } from 'react-icons/io5'

import AccordionCK from './AccordionCK'

const Ckcontent = [
  {
    title: 'Performance cookies',
    content:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know which content is interesting for you as a user and thus we could not improve it in the future.',
    isbtn: true,
  },
  {
    title: 'Performance cookies',
    content:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know which content is interesting for you as a user and thus we could not improve it in the future.',
    isbtn: false,
  },
  {
    title: 'Performance cookies',
    content:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know which content is interesting for you as a user and thus we could not improve it in the future.',
    isbtn: true,
  },
  {
    title: 'Performance cookies',
    content:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know which content is interesting for you as a user and thus we could not improve it in the future.',
    isbtn: true,
  },
  {
    title: 'Performance cookies',
    content:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know which content is interesting for you as a user and thus we could not improve it in the future.',
    isbtn: false,
  },
]

const Cookies = () => {
  let allowClick = 0
  const cookieName = 'mainCookie'
  const [show, setShow] = useState(true)
  const [showCK, setShowCK] = useState(false)
  const [cookieState, setCookieState] = useState(true)

  useEffect(() => {
    getCookie(cookieName) ? setCookieState(true) : setCookieState(false)
  }, [])

  const getCookie = (cookieName) => {
    const nameEQ = cookieName + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return true
    }
    return false
  }

  const setCookie = (cookieName, value, days) => {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = cookieName + '=' + (value || '') + expires + '; path=/'
  }

  return (
    <>
      {cookieState === false ? (
        <>
          <div
            className={`${
              show ? 'flex' : 'hidden'
            } flex flex-col container-fluid px-[48px] pt-[54px] pb-[42px] md:flex-row md:justify-center md:items-center md:px-[80px] fixed bottom-0 left-0 right-0 bg-white z-50`}
          >
            <IoClose
              className='absolute top-[12px] right-[12px] md:hidden'
              size={24}
              onClick={() => {
                setShow(false)
              }}
            />
            <p className='text-sm'>
              Use of cookies by clicking “Accept”, you agree to the storing of
              cookies on your device to enhance site navigation, analyze site
              usage, and assist in our marketing efforts.{' '}
              <Link to='/cookies' className='underline'>
                More about our cookies
              </Link>
            </p>
            <div className='flex flex-wrap justify-center gap-[25px] mt-[24px] md:flex-nowrap md:gap-[20px] md:mt-0 md:ml-[75px]'>
              <button
                className='primary-button hover:text-orange hover:bg-white'
                onClick={() => {
                  setShowCK(true)
                  document.querySelector('body').classList.add('no-scroll')
                }}
              >
                Settings
              </button>
              <button
                className='secondary-button'
                onClick={() => {
                  setShow(!show)

                  if (getCookie(cookieName) === false) {
                    setCookie(
                      cookieName,
                      'The user accepts cookie information.',
                      14
                    )
                  }
                }}
              >
                Accept
              </button>
            </div>
          </div>
          <div
            id='job-apply-container'
            className={`${
              showCK ? 'z-[50] opacity-100' : '-z-[50] opacity-0'
            } lg:flex lg:items-center fixed top-0 left-0 bottom-0 right-0 bg-[#7d858e40] backdrop-blur-[15px] transition-all duration-300 ease-in-out md:flex md:justify-center `}
          >
            <div
              id='advCookies'
              className={`overflow-y-auto relative flex flex-col w-100 h-full container bg-white z-[51] pt-[30px] pb-[30px] text-black-100 w-100 md:max-h-[700px] md:max-w-[600px] md:px-[32px] `}
            >
              <div className='flex align-middle justify-between'>
                <p className='text-xl leading-[32px] max-w-[215px] mb-[16px] md:max-w-[100%]'>
                  Privacy Preference Center
                </p>
                <IoClose
                  className=' md:absolute md:top-[12px] md:right-[12px] cursor-pointer '
                  size={24}
                  onClick={() => {
                    setShowCK(false)
                    if (
                      document
                        .querySelector('body')
                        .classList.contains('no-scroll')
                    )
                      document
                        .querySelector('body')
                        .classList.remove('no-scroll')
                  }}
                />
              </div>

              <p className='text-xs mb-[16px] md:mb-[25px]'>
                When you visit any website, it may store or retrieve information
                on your browser, mostly in the form of cookies. This information
                might be about you, your preferences or your device and is
                mostly used to make the site work as you expect it to. The
                information does not usually directly identify you, but it can
                give you a more personalized web experience.
              </p>
              <button
                className='primary-button self-start'
                onClick={() => {
                  const advCookies = document.querySelector('#advCookies')
                  if (allowClick === 0) {
                    advCookies.querySelectorAll('input').forEach((input) => {
                      if (input.type === 'checkbox') {
                        input.checked = true
                      }
                    })
                    allowClick++
                  } else {
                    advCookies.querySelectorAll('input').forEach((input) => {
                      if (input.type === 'checkbox') {
                        input.checked = false
                      }
                    })
                    allowClick = 0
                  }
                }}
              >
                Allow all
              </button>
              <p className='text-xl leading-[32px] max-w-[210px] mt-[16px] mb-[16px]  md:max-w-[100%]'>
                Manage Consent Preferences
              </p>

              {Ckcontent.map((accordion, index) => {
                return (
                  <AccordionCK
                    key={index}
                    title={accordion.title}
                    content={accordion.content}
                    isbtn={accordion.isbtn}
                    index={index}
                  />
                )
              })}
              <button
                className='primary-button self-start mt-[20px]'
                onClick={() => {
                  setShowCK(false)
                  if (
                    document
                      .querySelector('body')
                      .classList.contains('no-scroll')
                  )
                    document.querySelector('body').classList.remove('no-scroll')
                }}
              >
                Confirm my choices
              </button>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default Cookies
