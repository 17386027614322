import React, { useEffect } from 'react'
import Navbar from './Header'
import Footer from './Footer'
import Cursor from './Cursor'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../assets/css/main.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init()
  }, [])

  // If is page /contact, add captcha
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/contact/') {
      return (
        <>
          <Navbar />
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.CAPTCHA_V3_SITE_KEY}

            scriptProps={{
              async: true,
              defer: true,
              appendTo: 'body',
            }}>
            <main className='overflow-hidden text-black-100'>{children}</main>
          </GoogleReCaptchaProvider>
          <Cursor />
          <Footer />
        </>
      )
    }
  }

  return (
    <>
      <Navbar />
      <main className='overflow-hidden text-black-100'>{children}</main>
      <Cursor />
      <Footer />
    </>
  )
}

export default Layout
