import React from 'react'
import Cookies from '../components/Cookies'
import { Link } from 'gatsby'
import Logo from '../assets/svg/logo2.inline.svg'
import MenuItemsList from './MenuItemsList'

export const SocialMedia = [
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/inmotion-labs',
  },
]

const Footer = () => {
  return (
    <>
      <footer className='lg:pt-[80px] lg:gap-[105px] lg:flex-row md:pt-[100px] flex flex-col container pt-[40px] bg-black-100 text-white'>
        <div className='lg:flex flex-col w-full'>
          <div className='lg:flex gap-x-[105px]'>
            <div>
              <div className='flex flex-col gap-[32px] order-1'>
                <a href='/'>
                  <Logo />
                </a>
                <div>
                  <p className='text-xl mb-[22px]'>Get The Whole Image™</p>
                  <p className='lg:max-w-full md:max-w-[300px] text-sm'>
                    Robot-powered imaging and inspection for the automotive
                    industry.
                  </p>
                </div>
              </div>
              <div className='lg:mt-[55px] lg:flex md:hidden lg:order-2 flex flex-wrap gap-[22px] mb-[25px] mt-[32px]'>
                {SocialMedia.map((socials, index) => {
                  return (
                    <a
                      key={index}
                      href={socials.url}
                      className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                      rel='nofollow'
                    >
                      {socials.name}
                    </a>
                  )
                })}
              </div>
            </div>
            <div className='lg:w-full order-3'>
              <div className='lg:pt-0 md:border-[#7d858e1a] md:py-[32px] md:grid-cols-3 md:border-t-0 grid grid-cols-2 gap-y-[30px] py-[25px] border-y-[1px] border-black-200'>
                {MenuItemsList.filter(
                  (menuItem) =>
                    menuItem.title === 'Products' && menuItem.submenu
                ).map((menuItem) => {
                  const renderList = menuItem.submenu.map((submenu, index) => {
                    return (
                      <div key={index}>
                        <p className='md:mb-[32px] md:text-[20px] text-lg text-gray-300 mb-[16px]'>
                          {submenu.title}
                        </p>
                        <ul className='md:gap-y-[25px] flex flex-col gap-y-[30px]'>
                          {submenu.submenu.map((submenuL2, index2) => {
                            return (
                              <li key={index2}>
                                <Link
                                  to={submenuL2.url}
                                  className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                                >
                                  {submenuL2.title}
                                  <sup className='text-gray-300 ml-[5px] inline-block no-underline'>
                                    {submenuL2.status}
                                  </sup>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })
                  return renderList
                })}
              </div>
              <div className='lg:pb-[30px] md:gap-y-[30px] md:py-[32px] grid grid-cols-3 gap-y-[32px] py-[18px]'>
                {MenuItemsList.filter(
                  (menuItem) => menuItem.title !== 'Products'
                ).map((menuItem, index) => {
                  return (
                    <Link
                      key={index}
                      to={menuItem.url}
                      className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                    >
                      {menuItem.title}
                    </Link>
                  )
                })}
                <Link
                  to='/careers'
                  className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                >
                  Careers
                </Link>
                <Link
                  to='/contact'
                  className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                >
                  Contact
                </Link>
                <Link
                  to='/cookies'
                  className='lg:block text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                >
                  Cookies
                </Link>
                <Link
                  to='/privacy-policy'
                  className='lg:block text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
          <div className='lg:hidden md md:flex md:mb-[35px] md:pt-[56px] gap-[22px] mt-[32px] hidden order-3'>
            {SocialMedia.map((socials, index) => {
              return (
                <a
                  key={index}
                  href={socials.url}
                  className='text-sm hover:text-orange w-fit transition-all duration-200 ease-in-out'
                  rel='nofollow'
                >
                  {socials.name}
                </a>
              )
            })}
          </div>
          <div className='lg:pb-[100px] lg:pt-0 pb-[35px] md:pb-[85px] text-gray-300 pt-[14px] order-4'>
            <p>&copy; {new Date().getFullYear()} VUMO</p>
          </div>
        </div>
      </footer>
      <Cookies />
    </>
  )
}

export default Footer
