import './src/assets/css/main.css'
import React from 'react'
import Layout from './src/components/Layout'

export const wrapPageElement = ({ element, props }) => {
  if (!props.location.pathname.includes('try-it')) {
    return <Layout {...props}>{element}</Layout>
  }
}

export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}
