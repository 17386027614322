import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoClose } from 'react-icons/io5'

import Logo from '../assets/svg/logo.inline.svg'
import Chevron from '../assets/svg/chevron_menu.inline.svg'
import ArrowMenu from '../assets/svg/arrow-menu.inline.svg'
import { SocialMedia } from './Footer'
import MenuItemsList from './MenuItemsList'

import MEKV01 from '../assets/video/menu/desktop_menu_KV01.mp4'
import MEKV03 from '../assets/video/menu/desktop_menu_KV03.mp4'
import MEKV04 from '../assets/video/menu/desktop_menu_KV04.mp4'
import MEKV05 from '../assets/video/menu/desktop_menu_KV05.mp4'
import MEKV06 from '../assets/video/menu/desktop_menu_KV06.mp4'
import MEKV07 from '../assets/video/menu/desktop_menu_KV07.mp4'
import MEKV10 from '../assets/video/menu/desktop_menu_KV10.mp4'
import MEKV13 from '../assets/video/menu/desktop_menu_KV13.mp4'
import MEKV17 from '../assets/video/menu/desktop_menu_KV17.mp4'

const Header = () => {
  const [show, setShow] = useState(false)
  const [showSubmenu, setShowSubmenu] = useState(false)

  let lastScrollTop = 0
  const pageOffset = 10

  const stickyMenu = (header) => {
    const st = window.pageYOffset || document.documentElement.scrollTop

    if (!header.classList.contains('sticky')) {
      header.classList.add('sticky')
    } else {
      if (st > lastScrollTop && show !== true) {
        header.style = `transform: translate(-50%, -100%)`
      } else {
        header.style = ``
      }
      lastScrollTop = st <= 0 ? 0 : st
    }
  }

  const unStickyMenu = (header) => {
    if (show !== true) {
      if (header.classList.contains('sticky')) {
        header.classList.remove('sticky')
        header.style = ``
      }
    }
  }

  useEffect(() => {
    const header = document.getElementById('header')
    window.addEventListener('scroll', () => {
      window.scrollY >= pageOffset &&
      window.matchMedia('(max-width: 833px)').matches
        ? stickyMenu(header)
        : unStickyMenu(header)
    })
  })

  function loadProductInfo(productVideo, productTitle, productDescription) {
    const menuImportsObject = {
      MEKV03: MEKV03,
      MEKV04: MEKV04,
      MEKV05: MEKV05,
      MEKV06: MEKV06,
      MEKV07: MEKV07,
      MEKV10: MEKV10,
      MEKV13: MEKV13,
      MEKV17: MEKV17,
    }

    const menuVideo = document.querySelector('.product-menu')
    const productTitleDiv = document.querySelector('.product-title')
    const productDescriptionDiv = document.querySelector('.product-description')

    for (const [key, value] of Object.entries(menuImportsObject)) {
      if (key === productVideo) {
        menuVideo.style.opacity = '0'
        setTimeout(() => {
          menuVideo.setAttribute('src', value)
          menuVideo.style.opacity = '1'
        }, 200)
      }
    }

    productTitleDiv.innerHTML = productTitle
    productDescriptionDiv.innerHTML = productDescription
  }

  return (
    <>
      <header>
        <div
          id='header'
          className={`md:absolute left-1/2 -translate-x-1/2 flex w-full max-h-[90px] z-50  py-[30px] transition-all duration-500 origin-top ease-in-out fixed`}
        >
          <div className='container z-10 w-screen flex justify-between items-center'>
            <div id='logo' className='logo transition-all ease-in-out'>
              <Link
                to='/'
                onClick={() => {
                  setShow(false)
                  setShowSubmenu(false)

                  const body = document.querySelector('body')
                  if (body.classList.contains('no-scroll')) {
                    body.classList.remove('no-scroll')
                  }
                }}
              >
                <Logo />
              </Link>
            </div>
            <div className='hamburger md:hidden'>
              {show ? (
                <IoClose
                  size={18}
                  onClick={() => {
                    setShow(!show)
                    if (
                      !document
                        .querySelector('.products-submenu')
                        .classList.contains('submenu-open')
                    ) {
                      setShowSubmenu(!showSubmenu)
                    }
                  }}
                />
              ) : (
                <GiHamburgerMenu
                  size={18}
                  onClick={() => {
                    setShow(!show)
                  }}
                />
              )}
            </div>
            <div className='md:block hidden'>
              <ul className='lg:gap-[55px] md:gap-[32px] flex gap-[48px]'>
                {MenuItemsList.map((menuItem, index) => {
                  return (
                    <li key={index}>
                      {menuItem.submenu ? (
                        <>
                          <Link
                            to={menuItem.url}
                            className={`${
                              showSubmenu ? 'text-black-100' : 'text-gray-300'
                            } no-underline`}
                            onClick={(e) => {
                              e.preventDefault()
                              setShowSubmenu(!showSubmenu)
                            }}
                            onMouseEnter={() => {
                              setShowSubmenu(true)
                            }}
                          >
                            {menuItem.title}
                            <ArrowMenu
                              className={`${
                                showSubmenu ? 'menu-arrow' : ''
                              } rotate-180 inline-block ml-[8px] transition-all duration-300 ease-in-out`}
                            />
                          </Link>
                          <div
                            role='presentation'
                            className={`${
                              showSubmenu
                                ? 'scale-y-full desktop-submenu-open'
                                : 'scale-y-0'
                            } desktop-submenu w-full absolute top-full left-0 bg-gray-100 transition-all duration-100 origin-top flex overflow-hidden`}
                            onMouseLeave={() => {
                              setShowSubmenu(false)
                            }}
                          >
                            <div className='flex w-full container lg:pl-0'>
                              <div
                                className={`lg:flex flex-col min-w-[370px] max-w-[370px] hidden`}
                              >
                                <video
                                  disableRemotePlayback
                                  muted
                                  autoPlay
                                  loop
                                  className={`product-menu w-full h-[215px] object-cover opacity-1 duration-200 transition-all ease-in-out`}
                                >
                                  <source src={MEKV01} type='video/webm' />
                                </video>
                                <div className='bg-white w-full pl-[25px] pr-[25px] pt-[20px] pb-[25px] min-h-[160px] max-h-full'>
                                  <p className='product-title font-medium mb-[12px]'>
                                    Get the whole image™
                                  </p>
                                  <p className='product-description text-sm'>
                                    Automate your car photography and inspection
                                    with AI. See more with Vumo.
                                  </p>
                                </div>
                              </div>
                              <div className='w-full lg:ml-[155px] pt-[50px] pb-[65px]'>
                                <div className='grid grid-cols-3'>
                                  {MenuItemsList.filter(
                                    (menuItem) => menuItem.title === 'Products'
                                  ).map((menuItem) => {
                                    const renderSubmenu = menuItem.submenu.map(
                                      (submenu, index) => {
                                        return (
                                          <div key={index}>
                                            <p className='text-black-100 text-xl mb-[40px]'>
                                              {submenu.title}
                                            </p>
                                            <ul className='flex flex-col gap-[35px]'>
                                              {submenu.submenu.map(
                                                (product, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <Link
                                                        to={product.url}
                                                        className='text-base hover:text-orange transition-all duration-200 ease-in-out'
                                                        onClick={() => {
                                                          setShowSubmenu(false)
                                                        }}
                                                        onMouseEnter={() => {
                                                          loadProductInfo(
                                                            product.videoMenu,
                                                            product.title,
                                                            product.description
                                                          )
                                                        }}
                                                      >
                                                        {product.title}
                                                        <sup className='font-medium ml-[5px] inline-block no-underline text-black-100'>
                                                          {product.status}
                                                        </sup>
                                                      </Link>
                                                    </li>
                                                  )
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )
                                      }
                                    )
                                    return renderSubmenu
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Link
                          to={menuItem.url}
                          className={menuItem.className}
                          activeClassName={menuItem.activeClassName}
                          onClick={() => {
                            setShowSubmenu(false)
                          }}
                          onMouseEnter={() => {
                            setShowSubmenu(false)
                          }}
                        >
                          {menuItem.title}
                        </Link>
                      )}
                    </li>
                  )
                })}
                <li>
                  <Link
                    to='/contact'
                    className='md:block hover:text-orange cursor-pointer contact-button no-underline text-base text-black-100 relative hidden'
                  >
                    Book a demo
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Menu for mobile devices */}
          <div
            className={`${
              show ? 'translate-x-0' : 'translate-x-full'
            }  md:hidden flex flex-col justify-between w-screen h-screen absolute top-0 left-0 bg-gray-100 container pt-[100px] pb-[48px] transition-all duration-300 ease-in-out z-[5]`}
          >
            <nav className='w-full h-full flex flex-col justify-between'>
              <ul className='flex flex-col gap-[35px]'>
                {MenuItemsList.map((menuItem, index) => {
                  return (
                    <li key={index}>
                      {menuItem.submenu ? (
                        <div
                          key={index}
                          className='flex items-center gap-[12px]'
                        >
                          <span className='bg-black'>
                            <Chevron />
                          </span>
                          <Link
                            to={menuItem.url}
                            className={menuItem.className}
                            onClick={(e) => {
                              e.preventDefault()
                              setShowSubmenu(!showSubmenu)
                            }}
                          >
                            {menuItem.title}
                          </Link>
                          <div
                            className={`${
                              showSubmenu
                                ? 'translate-x-0'
                                : 'translate-x-full submenu-open'
                            } products-submenu pt-[100px] pb-[48px] container absolute top-0 left-0 w-screen h-screen bg-gray-100 overflow-scroll`}
                            style={{ clipPath: 'inset(80px 0 0 0)' }}
                          >
                            <span className='flex items-center gap-[12px] mb-[48px]'>
                              <Chevron
                                style={{ transform: 'rotate(180deg)' }}
                              />
                              <Link
                                to={menuItem.url}
                                className={menuItem.className}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setShowSubmenu(!showSubmenu)
                                }}
                              >
                                {menuItem.title}
                              </Link>
                            </span>
                            {menuItem.submenu.map((submenu, index) => {
                              return (
                                <div key={index} className='ml-[36px]'>
                                  <p className='text-2xl text-gray-300 mb-[24px]'>
                                    {submenu.title}
                                  </p>
                                  <ul className='flex flex-col gap-[25px] mb-[48px]'>
                                    {submenu.submenu.map((product, index) => {
                                      return (
                                        <li key={index}>
                                          <Link
                                            to={product.url}
                                            className='text-lg'
                                            onClick={() => {
                                              setShow(!show)
                                              setShowSubmenu(!showSubmenu)
                                              const body =
                                                document.querySelector('body')
                                              if (
                                                body.classList.contains(
                                                  'no-scroll'
                                                )
                                              ) {
                                                body.classList.remove(
                                                  'no-scroll'
                                                )
                                              }
                                            }}
                                          >
                                            {product.title}
                                            <sup className='inline-block ml-[5px] no-underline'>
                                              {product.status}
                                            </sup>
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={menuItem.url}
                          className={`${menuItem.className} ml-[36px]`}
                          activeClassName={menuItem.activeClassName}
                          onClick={() => {
                            setShow(!show)
                            const body = document.querySelector('body')
                            if (body.classList.contains('no-scroll')) {
                              body.classList.remove('no-scroll')
                            }
                          }}
                        >
                          {menuItem.title}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
              <Link
                to='/contact'
                className='secondary-button text-center font-normal py-[26px] mb-[48px]'
                onClick={() => {
                  setShow(false)
                }}
              >
                Book a demo
              </Link>
            </nav>
            <div className='flex justify-center flex-wrap gap-[40px]'>
              {SocialMedia.map((social, index) => {
                return (
                  <a
                    key={index}
                    href={social.url}
                    className='text-lg no-underline'
                    rel='nofollow'
                  >
                    {social.name}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div
          role='presentation'
          className={`${
            showSubmenu ? 'fixed' : 'hidden'
          } top-0 left-0 right-0 bottom-0 bg-[#FFFFFF30] backdrop-blur-[8px] z-10`}
          onMouseEnter={() => {
            setShowSubmenu(false)
          }}
        ></div>
      </header>
    </>
  )
}

export default Header
