import React, { useState } from 'react'
import { HiPlus } from 'react-icons/hi'
import { HiMinus } from 'react-icons/hi'

const AccordionCK = ({ title, content, isbtn, index }) => {
  const [isActive, setIsActive] = useState(false)

  const toggleFaq = (index) => {
    if (isActive === index) {
      return setIsActive(null)
    }
    setIsActive(index)
  }

  return (
    <div
      className='flex flex-col py-[16px] border-y-[1px] [&:not(:first-child)]:-mt-[1px] cursor-pointer'
      onClick={() => {
        toggleFaq(index)
      }}
      role='presentation'
    >
      <div className='flex items-center justify-between '>
        <div className='flex items-center '>
          <div>
            {isActive === index ? <HiMinus size={20} /> : <HiPlus size={20} />}
          </div>
          <p
            className={`${
              isActive === index ? 'font-bold' : 'font-medium'
            } text-base ml-[10px] `}
          >
            {title}
          </p>
        </div>

        <div>
          {isbtn ? (
            <label className='inline-flex relative items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer'></input>
              <div className="w-11 h-6 bg-white border border-gray-200 peer-focus:outline-none  peer-focus:ring-gray-200  rounded-full peer  peer-checked:after:translate-x-full  after:content-[''] after:absolute after:top-[2px] after:left-[3px] after:bg-gray-200   after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-white peer-checked:after:bg-orange peer-checked:after:left-auto"></div>
            </label>
          ) : (
            <p className='font-medium text-gray-300 '>always active</p>
          )}
        </div>
      </div>
      <div
        className={`${
          isActive === index ? 'block' : 'hidden'
        } mt-[24px] overflow-hidden origin-top transition-all duration-300 ease-in-out`}
      >
        <p className='text-xs text-gray-300'>{content}</p>
      </div>
    </div>
  )
}

export default AccordionCK
